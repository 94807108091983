import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Layout/Sidebar/index';
import Header from '../../../components/Header/index';

const Dashboard = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="container-fluid px-0">
                <div className="d-flex mx-0">
                    <Sidebar show={show} handleClose={handleClose} handleShow={handleShow} />
                    <div className="col-lg-10 col-12 flex-1">
                        <Header show={show} handleClose={handleClose} handleShow={handleShow} />
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
