import React, { useState } from 'react';
import FormInput from '../../../../components/FormInput/FormInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../api';

const Create = () => {

    const [values, setValues] = useState({ question: "", answer: "", });


    const [userError, setUserError] = useState('');

    const isValid = () => {
        return values.question !== "" || values.answer !== ""
    }

    const inputs = [
        {
            id: 1,
            name: "question",
            type: "text",
            placeholder: "Question",
            label: "Question",
            // errorMessage:
            //     "Question should be 3-20 characters",
            // required: true,
        },
        {
            id: 2,
            name: "answer",
            type: "text",
            placeholder: "Answer",
            label: "Answer",
            // errorMessage: "Answer should be 250 characters",
            // required: true,
        }
    ];

    const handleSubmit = () => {
        // console.log(values)
        let body = {
            question: values.question,
            answer: values.answer,
        }
        http.post('/faq/add', body).then((res) => {
            console.log(res);
            toast.success('Faq added successfully', { position: "top-right" });
            setValues({ question: "", answer: "" });
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <>
            <ToastContainer />

            <div className="create-project">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Add Question</h2>
                            {inputs.map((input) => (
                                <FormInput
                                    key={input.id}
                                    {...input}
                                    value={values[input.name]}
                                    onChange={onChange}
                                />
                            ))}
                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100'>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create
