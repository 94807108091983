export const environment = {
	// api_url: "http://localhost:8000/api",
	// file_url: "http://localhost:8000",
	// front_end: "http://localhost:3000",

	api_url: "https://metadots.co/api",
	file_url: "https://metadots.co",
	front_end: "https://metadots.co",

	googleBucket: "/assets/images/",

	imageTypes: ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/svg", "image/svg+xml", "image/webp"],
	fileTypes: [
		"application/pdf",
		"application/docx",
		"application/doc",
		"application/xlsx",
		"application/xls",
		"application/pptx",
		"application/ppt",
		"application/zip",
		"application/x-zip",
		"application/x-zip-compressed",
		"application/octet-stream",
		"application/x-compress",
		"application/x-compressed",
		"multipart/x-zip",
		"application/msword",
		"text/plain",
	],
};
