import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../api';
import { environment } from '../../../../constants';

const Update = () => {


    const [values, setValues] = useState({
        feedback: "",
        fullName: "",
        company: "",
        profileImage: "",
    });

    const { slug } = useParams();

    const [userError, setUserError] = useState('');

    const isValid = () => {
        return values.feedback !== "" || values.fullName !== "" || values.company !== "" || values.profileImage !== ""
    }



    const handleSubmit = () => {
        console.log(values)
        let body = {
            feedback: values.feedback,
            fullName: values.fullName,
            company: values.company,
            profileImage: values.profileImage
        }
        http.put('/review/edit/' + slug, body).then((res) => {
            console.log(res)
            toast.success('Review updated successfully', { position: "top-right" })
            setValues({
                feedback: "",
                fullName: "",
                company: "",
                profileImage: "",
            })
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };

    const onThumbnailChange = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);

        axios.post(environment.api_url + "/upload", formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then((res) => {
            setValues({ ...values, profileImage: res.data.url });
            console.log(res.data.url)
        });
    };

    const getReview = () => {
        http.get('/review/' + slug).then((res) => {
            console.log(res)
            setValues(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        getReview();

    }, [slug])


    return (
        <>
            <ToastContainer />
            <div className="create-project">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Update Review</h2>
                            {values.profileImage &&
                                <>
                                    <div className="client-image mx-auto">
                                        <img src={values?.profileImage} alt="client-image" />
                                        <a className="cross-icon" onClick={() => setValues({ ...values, profileImage: "" })}>&times;</a>
                                    </div>
                                </>
                            }
                            {!values.profileImage && (
                                <div className="input-area-user mb-3">
                                    <input type="file" onChange={(e) => onThumbnailChange(e)} />
                                    <div>Click to upload</div>
                                </div>
                            )}
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Full Name</label>
                                <input type="text" placeholder='FullName' value={values?.fullName} onChange={(e) => setValues({ ...values, fullName: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="company" className="form-label">Company</label>
                                <input type="text" placeholder='Company' value={values?.company} onChange={(e) => setValues({ ...values, company: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="feedback" className="form-label">Feedback</label>
                                <textarea type="text" placeholder='Feedback' value={values?.feedback} onChange={(e) => setValues({ ...values, feedback: e.target.value })} className="form-control">
                                </textarea>
                            </div>

                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100'>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update
