import React from 'react'

const socialIcons = () => {
    return (
        <>
            <div class="social-icons">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/metadots.co/" target="_blank" rel="noopener noreferrer">
                            <div className="icon">
                                <iconify-icon icon="mdi:instagram"></iconify-icon>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/metadots" target="_blank" rel="noopener noreferrer">
                            <div className="icon">
                                <iconify-icon icon="mdi:linkedin"></iconify-icon>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/Metadotsco" target="_blank" rel="noopener noreferrer">
                            <div className="icon">
                                <iconify-icon icon="mdi:twitter"></iconify-icon>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default socialIcons
