import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './general.css';
import { useParams } from "react-router-dom";
import axios from 'axios';
import http from '../../../../api';
import { environment } from '../../../../constants';

const Update = () => {


    const [values, setValues] = useState({ title: "", description: "", technology: "", projectImage: "" });

    const { slug } = useParams();
    const [userError, setUserError] = useState('');

    const isValid = () => {
        return values.title !== "" || values.description !== "" || values.technology !== "" || values.projectImage !== ""
    }

    const handleSubmit = () => {
        console.log(values)
        let body = {
            title: values.title,
            description: values.description,
            technology: values.technology,
            projectImage: values.projectImage
        }
        http.put('/project/edit/' + slug, body).then((res) => {
            console.log(res)
            toast.success('Project update successfully', { position: "top-right" })
            setValues({ title: "", description: "", technology: "", projectImage: "", });
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };


    const onThumbnailChange = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);

        axios.post(environment.api_url + "/upload", formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then((res) => {
            setValues({ ...values, projectImage: res.data.url });
            console.log(res.data.url)
        });
    };

    const getProject = () => {
        http.get('/project/' + slug).then((res) => {
            console.log(res)
            setValues(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        getProject();

    }, [slug])



    return (
        <>
            <ToastContainer />
            <div className="create-project scroll">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Update Project</h2>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <input type="text" placeholder='Title' value={values?.title} onChange={(e) => setValues({ ...values, title: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea type="text" placeholder='Description' value={values?.description} onChange={(e) => setValues({ ...values, description: e.target.value })} className="form-control">
                                </textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="techStack" className="form-label">Tech Stack</label>
                                <input type="text" placeholder='Tech Stack' value={values?.technology} onChange={(e) => setValues({ ...values, technology: e.target.value })} className="form-control" />
                            </div>
                            {values.projectImage &&
                                <>
                                    <div className="project-image">
                                        <img src={values?.projectImage} alt="project-image" />
                                        <a className="cross-icon" onClick={() => setValues({ ...values, projectImage: "" })}>
                                            x
                                        </a>
                                    </div>
                                </>
                            }
                            {!values.projectImage && (
                                <div className="input-area mb-3">
                                    <input type="file" onChange={(e) => onThumbnailChange(e)} />
                                    <div>Drag and drop files here</div>
                                </div>
                            )}
                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100'>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update
