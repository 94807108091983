import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import http from '../../../../../api';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { environment } from '../../../../../constants';


const Detail = () => {

    const { slug } = useParams();
    const [detail, setDetail] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [projectDetail, setProjectDetail] = useState({ heading: '', description: '', img: '' });

    const getDetailProject = () => {
        http.get(`/project/${slug}`).then((res) => {
            console.log(res);
            setDetail(res.data.data.detail);
        }).catch((e) => {
            console.log(e)
        })
    }

    const OnDetailImageChange = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);

        axios.post(environment.api_url + "/upload", formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then((res) => {
            setProjectDetail({ ...projectDetail, img: res.data.url });
            console.log(res.data.url)
        });
    };

    const getDetail = (id) => {
        http.get(`/project/detail/${slug}/${id}`).then((res) => {
            console.log(res);
            setProjectDetail(res.data.data);
            handleShow();
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleSubmit = (id) => {
        // console.log(projectDetail);
        let body = projectDetail
        http.put(`/project/detail/edit/${slug}/${id}`, body).then((res) => {
            console.log(res);
            toast.success("Detail successfully Updated");
            getDetailProject();
            handleClose();
        }).catch((e) => {
            console.log(e);
        })
    }

    const deleteDetail = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/project/detail/${slug}/${id}`).then((res) => {
                    console.log(res);
                    getDetailProject();
                }).catch((e) => {
                    console.log(e)
                });
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    useEffect(() => {

        getDetailProject();

    }, [])



    return (
        <>
            <ToastContainer />
            <div className="text-end my-4">
                <Link to={`/detail/create/${slug}`} className='btn btn-primary'>Add Detail</Link>
            </div>
            {detail?.length > 0 && <h1></h1>}
            <div className="detail-scroll">
                <div className="row align-items-start mx-0 pt-4">
                    {detail?.map((item, i) => {
                        const { heading, description, img, _id } = item;
                        return (
                            <div className="col-lg-4" key={i}>
                                <div className="card p-3 h-100">
                                    <div className="project-detail-img mb-3">
                                        <img src={img} alt="" />
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="d-flex flex-column">
                                            <h2>{heading}</h2>
                                            <h6>{description}.</h6>
                                        </div>
                                        <div className="drop-icon">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                                    <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu variant="light">
                                                    <Dropdown.Item onClick={() => getDetail(_id)}>Update</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => deleteDetail(_id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                </div>

            </div>
            <Modal show={show} onHide={handleClose} centered animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Detail Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label"> Heading</label>
                        <input type="text" placeholder='Heading' value={projectDetail.heading} onChange={(e) => setProjectDetail({ ...projectDetail, heading: e.target.value })} className='form-control' />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Description</label>
                        <input type="text" placeholder='Description' value={projectDetail.description}
                            onChange={(e) => setProjectDetail({ ...projectDetail, description: e.target.value })}
                            className='form-control' />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Project Image</label>
                        {projectDetail.img &&
                            <>
                                <div className="project-image">
                                    <img src={projectDetail.img} alt="project-image" />
                                    <a className="cross-icon" onClick={() => setProjectDetail({ ...projectDetail, img: "" })}>
                                        x
                                    </a>
                                </div>
                            </>
                        }
                        {!projectDetail.img && (
                            <div className="input-area mb-3">
                                <input type="file" onChange={(e) => OnDetailImageChange(e)} />
                                <div>Drag and drop files here</div>
                            </div>
                        )}
                    </div>
                    <button type='button' onClick={() => handleSubmit(projectDetail._id)} className='btnPrimary w-100'>Update</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Detail
