import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import http from '../../../../api';

const Subscription = () => {

    const [allSubscriber, setAllSubscriber] = useState([]);

    const getSubscriber = () => {
        http.get('/subscription/get-all').then((res) => {
            console.log(res)
            setAllSubscriber(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }



    const deleteSubscriber = (slug) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete('/subscription/' + slug).then((res) => {
                    console.log(res);
                    getSubscriber();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })

    }

    useEffect(() => {

        getSubscriber();

    }, [])
    return (
        <>
            <div className="mt-4">
                <h1 className='text-center mb-4'>All Subscription</h1>
                <div className="row mx-0">
                    {allSubscriber.map((item, i) => {
                        const { email, slug } = item
                        return (
                            <div className="col-lg-4 mb-3" key={i}>
                                <div className="card p-3">
                                    <div className='text-end'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                                <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu variant="light">
                                                <Dropdown.Item onClick={() => deleteSubscriber(slug)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="card-title">{email}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Subscription
