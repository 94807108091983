import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './project.css'
import http from '../../../../api';


const Project = () => {

    const [projects, setProjects] = useState([]);

    const getAllProjects = () => {
        http.get('/project/get-all').then((res) => {
            // console.log(res)
            setProjects(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }

    const deleteProject = (slug) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/project/${slug}`).then((res) => {
                    // console.log(res)
                    getAllProjects();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    useEffect(() => {

        getAllProjects();

    }, [])


    return (
        <>
            <div className="add-project">
                <div className="text-end my-4">
                    <Link to="create" className='btn btn-primary'>Add Project</Link>
                </div>
                {projects?.length > 0 && <h1 className="text-center">All Projects</h1>}
                {projects?.length === 0 && <h1 className="text-center">Projects Not Found!</h1>}
                <div className="row mx-0 mt-4">
                    {projects?.map((item, i) => {
                        return (
                            <div className="col-lg-4 col-md-6 mb-4" key={i}>
                                <div className="card h-100">
                                    <Link to={`detail/${item?.slug}`}>
                                        <div className="card-img">
                                            <img src={item?.projectImage} className="" alt="" />
                                        </div>
                                    </Link>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 className="card-title mb-0">{item?.title}</h5>
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-button-example1" variant="success">
                                                        <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu variant="light">
                                                        <Dropdown.Item as={NavLink} to={`update/${item?.slug}`}>Update</Dropdown.Item>
                                                        <Dropdown.Item className='d-flex' onClick={() => deleteProject(item?.slug)}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>
                                        <p className="card-text text-truncate">{item?.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Project
