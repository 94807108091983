import React, { useEffect } from 'react';
import './details.css';
import detailImg from '../../assets/detail-img.png';
import projectsDetail from '../../utilities/projects.json';
import { useParams } from 'react-router-dom';

const Details = () => {

    const { id } = useParams();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <div className="details-page">
                <div className="first-section">
                    <div className="container">
                        <div className="row align-items-baseline">
                            <div className="col-lg-6">
                                <div className="count-number">
                                    0{id == 0 ? 1 : id == 1 ? 2 : id == 2 ? 3 : id == 3 ? 4 : id == 4 ? 5 : id == 5 ? 6 : id == 6 ? 7 : id == 7 && 8}
                                </div>
                                <div className="project-heading">
                                    {projectsDetail[id].name}
                                </div>
                                <div className="project-des w-75">
                                    {projectsDetail[id].desc}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="fs-40 fw-600 textSecondary mb-1">
                                    What we did
                                </div>
                                <div className="fs-20 fw-500 text-grey">
                                    {projectsDetail[id].whatWeDid}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5 pt-5 pb-5 mb-5">
                    {projectsDetail[id].details.map((project, i) => {
                        const isEven = i % 2 === 0;
                        const condition = isEven ? 'even' : 'odd';
                        return (
                            <React.Fragment key={i}>
                                {condition === 'even' &&
                                    <div className="row align-items-end mt-5">
                                        <div className="col-lg-5 pb-5">
                                            <div className="content-detail right-line position-relative w-100">
                                                <div className="fs-40 fw-600 textSecondary mb-2">{project.heading}</div>
                                                <div className="fs-20 fw-500 text-grey">{project.desc} </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className={"detail-img " + project.class}>
                                                <img src={project.image} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {condition === 'odd' &&
                                    <div className="row align-items-end sm-reverse mt-5">
                                        <div className="col-lg-7">
                                            <div className={"detail-img " + project.class}>
                                                <img src={project.image} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-5 pb-5">
                                            <div className="content-detail left-line position-relative w-100 text-end">
                                                <div className="fs-40 fw-600 textSecondary mb-2">{project.heading}</div>
                                                <div className="fs-20 ms-auto fw-500 text-grey">{project.desc}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                    }



                </div>
            </div>
        </>
    )
}

export default Details
