import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Style/nav.css';
import http from '../api';
import Alert from 'react-bootstrap/Alert';


export const NavBar = ({ show, setShow, handleClose, handleShow }) => {

  const [quoteValue, setQuoteValue] = useState({ fullName: '', email: '', message: '' });
  const [messageShow, setMessageShow] = useState(false);


  const isValid = () => {
    return quoteValue.fullName.length > 0 && quoteValue.email.length > 0 && quoteValue.message.length > 0;
  }
  const handleSubmit = () => {
    // console.log(quoteValue)
    http.post('/quote/new', quoteValue).then((res) => {
      console.log(res);
      if (res) {
        setMessageShow(true)
      }
      setQuoteValue({ fullName: '', email: '', message: '' });
    }).catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setMessageShow(false)
    }, 5000);
  }, [messageShow])



  return (
    <>
      <Navbar key={'lg'} bg="white" collapseOnSelect expand={'lg'} sticky="top" className="navbarCustom">
        <Container>
          <Navbar.Brand as={NavLink} to="/"><img src={logo} className="ms-3 ms-md-0" alt="" /></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end align-items-lg-center flex-grow-1 pe-3">
                <Nav.Link as={NavLink} exact eventKey="1" to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="2" to="portfolio">
                  Portfolio
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="3" to="about">
                  About Us
                </Nav.Link>
                <Nav.Link as={NavLink} eventKey="4" to="career">
                  Career
                </Nav.Link>
                {/* <button className="btn btnPrimary ms-lg-2 mt-lg-0 mt-4" type='button'>
                  Get a Quote
                </button> */}
                <button id="cubic" type='button' onClick={handleShow} className="ms-lg-2 mt-lg-0 mt-4" >
                  <span title=' Get a Quote'> Get a Quote</span>
                </button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose} placement={'end'} className="getAQuote">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title ></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1 className="fw-700 mb-4">Get a Quote <span class="horizontalLine"></span></h1>
          {messageShow && <Alert variant={'info'} className="mb-4">
            Your message has been sent successfully.
          </Alert>}
          <div className="row mx-0 g-0">
            <div className="col-lg-6 mb-3 pe-lg-3">
              <div class="inputWithIcon">
                <span className="iconify" data-icon="ant-design:user-outlined"></span>
                <input type="text" value={quoteValue.fullName} onChange={(e) => setQuoteValue({ ...quoteValue, fullName: e.target.value })} placeholder="Full Name" />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="inputWithIcon">
                <span className="iconify" data-icon="ant-design:mail-outlined"></span>
                <input type="email" value={quoteValue.email} onChange={(e) => setQuoteValue({ ...quoteValue, email: e.target.value })} placeholder="Email Address" />
              </div>
            </div>
            <div className="col-12 mb-3">
              <textarea rows="15" value={quoteValue.message} onChange={(e) => setQuoteValue({ ...quoteValue, message: e.target.value })} placeholder="Please tell us how can we help you?"></textarea>
            </div>
            <div>
              <button id="cubic" type='button' disabled={!isValid()} onClick={() => handleSubmit()}>
                <span title='Send Message'>Send Message</span>
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
