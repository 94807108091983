import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import http from '../../../api';
import FormInput from '../../../components/FormInput/FormInput';
import './Style/Login.css';

const Login = () => {

    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const inputs = [
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "Email",
            errorMessage: "It should be a valid email address!",
            label: "Email",
            required: true,
        },
        {
            id: 2,
            name: "password",
            type: "password",
            placeholder: "Password",
            errorMessage:
                "Password should be 8 characters and include at least 1 letter, 1 number and 1 special character!",
            label: "Password",
            // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
            required: true,
            icon: true,
        },
    ];

    const navigate = useNavigate();

    const [userError, setUserError] = useState('');

    const isValid = () => {
        return values.email !== "" && values.password !== "";
    }

    const handleSubmit = () => {
        // console.log(values)
        http.post('/user/login', { user: values }).then((res) => {
            // console.log(res);
            setValues({ email: "", password: "" })
            window.localStorage.setItem("userData", JSON.stringify(res.data.data.user));
            navigate('/admin')
            window.location.reload();
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };


    return (
        <>
            <div className="Auth">
                <div className="row justify-content-center mx-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="card p-4 h-100">
                            <h2 className='text-center'>Login</h2>
                            {inputs.map((input) => (
                                <FormInput
                                    key={input.id}
                                    {...input}
                                    value={values[input.name]}
                                    onChange={onChange}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") handleSubmit();
                                    }}
                                />
                            ))}
                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            {/* <div className="text-center mb-3">
                                <Link to="/forget">Forget Password</Link>
                            </div> */}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100 mt-3'>Login</button>
                            {/* <div className="mt-4 text-center">
                                <span className='d-inline-block text-secondary small me-4'>Don't have an account yet?</span>
                                <Link to="/register">Sign Up</Link>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
            <div class="custom-shape-divider-bottom-1673109761">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
        </>
    )
}

export default Login
