import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormInput from '../../../../components/FormInput/FormInput';
import './general.css';
import http from '../../../../api';
import { environment } from '../../../../constants';


const Create = () => {


    const [values, setValues] = useState({
        title: "",
        description: "",
        technology: "",
        projectImage: "",
    });


    const [userError, setUserError] = useState('');

    const isValid = () => {
        // return values.title !== "" || values.description !== "" || values.technology !== ""
        return values.title !== "" || values.description !== "" || values.technology !== "" || values.projectImage !== ""
    }

    const inputs = [
        {
            id: 1,
            name: "title",
            type: "text",
            placeholder: "Title",
            label: "Title",
            // errorMessage:
            //     "Title should be 3-20 characters",
            // required: true,
        },
        {
            id: 2,
            name: "description",
            type: "text",
            placeholder: "Description",
            label: "Description",
            // errorMessage: "Description should be 250 characters",
            // required: true,
        },
        {
            id: 3,
            name: "techStack",
            type: "text",
            placeholder: "Tech Stack",
            label: "Tech Stack",
            // errorMessage:
            //     "Tech Stack required!",
            // required: true,
        }

    ];

    const handleSubmit = () => {
        console.log(values)
        let body = {
            title: values.title,
            description: values.description,
            technology: values.technology,
            projectImage: values.projectImage
        }
        http.post('/project/new', body).then((res) => {
            // console.log(res)
            setValues({ title: "", description: "", technology: "", projectImage: "", });
            toast.success('Project added successfully', { position: "top-right" })
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };



    const onThumbnailChange = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);

        axios.post(environment.api_url + "/upload", formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then((res) => {
            setValues({ ...values, projectImage: res.data.url });
            console.log(res.data.url)
        });
    };

    return (
        <>
            <ToastContainer />
            <div className="create-project">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Add Project</h2>
                            {inputs.map((input) => (
                                <FormInput
                                    key={input.id}
                                    {...input}
                                    value={values[input.name]}
                                    onChange={onChange}
                                />
                            ))}
                            {values.projectImage &&
                                <>
                                    <div className="project-image">
                                        <img src={values.projectImage} alt="project-image" />
                                        <a className="cross-icon" onClick={() => setValues({ ...values, projectImage: "" })}>
                                            x
                                        </a>
                                    </div>
                                </>
                            }
                            {!values.projectImage && (
                                <div className="input-area mb-3">
                                    <input type="file" onChange={(e) => onThumbnailChange(e)} />
                                    <div>Drag and drop files here</div>
                                </div>
                            )}
                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100'>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create
