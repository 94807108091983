import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../api';


const Update = () => {

    const { slug } = useParams();

    const [jobValues, setJobValues] = useState({ title: '', location: '', experience: '', description: '' });

    const getJob = () => {
        http.get(`/job/${slug}`).then((res) => {
            console.log(res);
            setJobValues(res.data.data);
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleSubmit = () => {
        // console.log(jobValues);
        let body = jobValues
        http.put(`/job/${slug}`, body).then((res) => {
            console.log(res);
            toast.success("Job Update successfully");
            setJobValues({ title: '', location: '', experience: '', description: '' });
        }).catch((e) => {
            console.log(e)
        });
    };

    useEffect(() => {

        getJob();

    }, [])


    return (
        <>
            <ToastContainer />


            <div className="create-project">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Add Job</h2>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <input type="text" placeholder='Title' value={jobValues.title} onChange={(e) => setJobValues({ ...jobValues, title: e.target.value })} className='form-control' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="location" className="form-label">Location</label>
                                <input type="text" placeholder='Location' value={jobValues.location} onChange={(e) => setJobValues({ ...jobValues, location: e.target.value })} className='form-control' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="experience" className="form-label">Experience</label>
                                <input type="text" placeholder='Experience' value={jobValues.experience} onChange={(e) => setJobValues({ ...jobValues, experience: e.target.value })} className='form-control' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea placeholder='Description' value={jobValues.description} onChange={(e) => setJobValues({ ...jobValues, description: e.target.value })} className='form-control'></textarea>
                            </div>
                            <button type='button' onClick={handleSubmit} className='btnPrimary w-100'>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update