import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../../api';
import { environment } from '../../../../../constants';

const Create = () => {

    const [projectDetail, setProjectDetail] = useState({ heading: '', description: '', img: '' });

    const OnDetailImageChange = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("file", file);

        axios.post(environment.api_url + "/upload", formData, {
            headers: { 'content-type': 'multipart/form-data' }
        }).then((res) => {
            setProjectDetail({ ...projectDetail, img: res.data.url });
            console.log(res.data.url)
        });
    };

    const { slug } = useParams();


    const handleSubmit = () => {
        console.log(projectDetail)
        let body = projectDetail

        http.post('/project/detail/' + slug, body).then((res) => {
            console.log(res);
            toast.success('Project Detail added successfully', { position: "top-right" })
            setProjectDetail({ heading: '', description: '', img: '' })
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="create-project scroll">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-8">
                        <div className="card p-3">
                            <h2 className="text-center mb-3">Project Details</h2>

                            <div className="mb-3 mt-3">
                                <label className="form-label"> Heading</label>
                                <input type="text" placeholder='Heading' value={projectDetail.heading} onChange={(e) => setProjectDetail({ ...projectDetail, heading: e.target.value })} className='form-control' />
                            </div>
                            <div className="mb-3">
                                <label className="form-label"> Description</label>
                                <input type="text" placeholder='Description' value={projectDetail.description}
                                    onChange={(e) => setProjectDetail({ ...projectDetail, description: e.target.value })}
                                    className='form-control' />
                            </div>
                            <div className="mb-3">
                                <label className="form-label"> Project Image</label>
                                {projectDetail.img &&
                                    <>
                                        <div className="project-image">
                                            <img src={projectDetail.img} alt="project-image" />
                                            <a className="cross-icon" onClick={() => setProjectDetail({ ...projectDetail, img: "" })}>
                                                x
                                            </a>
                                        </div>
                                    </>
                                }
                                {!projectDetail.img && (
                                    <div className="input-area mb-3">
                                        <input type="file" onChange={(e) => OnDetailImageChange(e)} />
                                        <div>Drag and drop files here</div>
                                    </div>
                                )}
                            </div>
                            <button type='button' onClick={handleSubmit} className='btnPrimary mb-3'>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create
