import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const Protected = () => {

    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {

        setAuthUser(JSON.parse(localStorage.getItem("userData")));

    }, [])

    return authUser ? <Navigate replace to="/admin" /> : <Navigate replace to="/admin/login" />

}

export default Protected
