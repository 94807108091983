import React, { useEffect } from 'react';
import './about.css';
import aboutImg from '../../assets/about-img.png';
import beforeBox from '../../assets/before-box.svg';
import handIcon from '../../assets/hand-icon.svg';
import dedicated from '../../assets/dedicated-icon.svg';
import price from '../../assets/price-icon.svg';
import support from '../../assets/support-icon.svg';
import CountUp from 'react-countup';
import faqData from '../../utilities/faq.json';
import Accordion from 'react-bootstrap/Accordion';

const About = ({ show, setShow, handleClose, handleShow }) => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <div className="about-section">
                <div className="height-screen">
                    <div className="container ">
                        <div className="row mt-md-5 mb-5 mb-md-0">
                            <div className="col-lg-4 col-md-6">
                                <h1 className="fw-700 mb-3">
                                    We’re team of
                                    creatives & experts
                                </h1>
                                <div className="fs-20 fw-500 text-grey">
                                    We are a technology consulting organization that connects strategy, design and engineering services into a seamless workflow devised to support clients every step of the way
                                </div>
                                <div className='mt-4'>
                                    {/* <button type='button' className='btnPrimary'>Learn More</button> */}
                                    <button id="cubic" type='button' onClick={handleShow} className="" >
                                        <span title='Learn More'>Get Started</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 offset-lg-3">
                                <div className="about-img position-relative">
                                    <img src={aboutImg} alt="" className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="core-section">
                    <div className="container">
                        <div className="d-flex align-items-center gap-4 justify-content-center mb-4">
                            <div className="before-img">
                                <img src={beforeBox} alt="" />
                            </div>
                            <div className="fs-60 fw-700 textSecondary position-relative">
                                Our Core Values
                                <span className="horizontalLine"></span>
                            </div>
                        </div>
                        <div className="fs-20 fw-500 textSecondary text-center  mb-lg-5 pb-lg-5">Save time and resources by working with us, we deliver results that drive growth and success for
                            <br className='d-md-block d-none' /> you and provide frictionless experience for your customers.</div>
                        <div className="mt-lg-5 pt-md-4">
                            <div className="row position-relative mt-5 mb-5">
                                <div className="col-lg-4 mb-4 mb-md-0">
                                    <div className="core-card shadow">
                                        <div className="hand-icon mb-5">
                                            <img src={handIcon} alt="" />
                                        </div>
                                        <div className="fs-22 fw-600 textSecondary mb-1 text-capitalize">Commitment</div>
                                        <div className="fs-14 fw-600 text-darkgrey text-uppercase mb-4">We commit to YOUR vision</div>
                                        <div className="fs-14 fw-500 textSecondary">Our team of experts is committed to execute your ideas and grow your business.</div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4 mb-md-0">
                                    <div className="core-card shadow">
                                        <div className="hand-icon mb-5">
                                            <img src={handIcon} alt="" />
                                        </div>
                                        <div className="fs-22 fw-600 textSecondary mb-1 text-capitalize">Consistency</div>
                                        <div className="fs-14 fw-600 text-darkgrey text-uppercase mb-4">We believe in relentlessness</div>
                                        <div className="fs-14 fw-500 textSecondary">We turn your idea into a reality with consistency and dedication.</div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="core-card shadow">
                                        <div className="hand-icon mb-5">
                                            <img src={handIcon} alt="" />
                                        </div>
                                        <div className="fs-22 fw-600 textSecondary mb-1 text-capitalize">Transparency</div>
                                        <div className="fs-14 fw-600 text-darkgrey text-uppercase mb-4">You are always in the loop</div>
                                        <div className="fs-14 fw-500 textSecondary">We believe you should what’s going on with your project and we make sure you do.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="model-section mt-lg-5 pt-lg-5 pt-md-4">
                    <div className="container mt-5">
                        <div className="d-flex align-items-start gap-5 mb-4">
                            <div className="before-img mt-3">
                                <img src={beforeBox} alt="" />
                            </div>
                            <div className="fs-60 fw-700 textSecondary position-relative">
                                ENGAGEMENT MODELS <br />
                                AND PAYMENT
                                <span className="horizontalLine"></span>
                            </div>
                        </div>
                        <div className="row mt-md-5 pt-5">
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <iconify-icon icon="fluent:people-team-20-regular"></iconify-icon>
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        Dedicated Team
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        We gather a project team best suited for your project that iteratively creates the product with maximum speed and by applying an agile methodology.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <iconify-icon icon="fluent:window-dev-edit-20-regular"></iconify-icon>
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        Hire a developer
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        Through Metadots, you can vet and hire experienced software engineers, developers, designers and quality assurance engineers that match your time zone, project requirements and work model.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <iconify-icon icon="akar-icons:person-check"></iconify-icon>
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        Project Ownership
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        You are a start-up founder and don’t want to get into the hassle of hiring and managing tech team? We got you covered. Our team of honed project managers will communicate with you to deliver the best product for your idea so you can focus on other aspects of the business with your mind at peace.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <img src={dedicated} alt="" />
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        Quality Assurance
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        Our team of QA engineers can help you improve software development processes and avoid production issues in various fields.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <iconify-icon icon="material-symbols:support-agent"></iconify-icon>
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        Support & Maintenance
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        Metadots provides support & maintenance services for products and projects already in production. Our team can manage and improve user experience for existing product.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-4">
                                <div className="model-card">
                                    <div className="model-icon mb-3">
                                        <iconify-icon icon="mdi:cloud-cog-outline"></iconify-icon>
                                    </div>
                                    <div className="fs-24 fw-600 textSecondary mb-3">
                                        DevOps
                                    </div>
                                    <div className="fs-14 fw-500 textSecondary">
                                        The world is moving towards cloud and so are we. Metadots has a team of dedicated DevOps engineers ready to optimize your cloud infrastructure and reduce costs.
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <section className="yearsExperince mt-md-5 pt-5">
                    <div className="container">
                        <div className="row mx-0 align-items-center ff-rubik">
                            <div className="col-md-4 text-center">
                                <div className="fs-100 fw-700 mb-2">
                                    <CountUp duration={3} end={6} />
                                    +</div>
                                <div className="fs-30 fw-700 textSecondary">Years of experience</div>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="fs-100 fw-700 mb-2">
                                    <CountUp end={50} />
                                    +</div>
                                <div className="fs-30 fw-700 textSecondary">Happy Clients</div>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="fs-100 fw-700 mb-2">
                                    <CountUp end={200} />
                                    +</div>
                                <div className="fs-30 fw-700 textSecondary">Successful Projects</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="faq-section pb-md-5 mb-5">
                    <div className="container">
                        <div className="d-flex align-items-start gap-5 mb-4">
                            <div className="before-img mt-3">
                                <img src={beforeBox} alt="" />
                            </div>
                            <div className="fs-60 fw-700 textSecondary position-relative">
                                Frequently asked questions
                                <span className="horizontalLine"></span>
                            </div>
                        </div>

                        <div className="faqs mt-md-5 pt-4">
                            <Accordion defaultActiveKey={0}>
                                {faqData.faqs.map((faq, i) => {
                                    return (
                                        <Accordion.Item eventKey={i}>
                                            <Accordion.Header>{faq.question}</Accordion.Header>
                                            <Accordion.Body>
                                                <p key={i}>
                                                    {faq.answer}
                                                </p>
                                                {faq.list &&
                                                    <ul>
                                                        {faq.list.map((item, i) => {
                                                            return <li key={i}>{item.bullet}</li>
                                                        })}
                                                    </ul>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default About
