import React, { useEffect } from 'react';
import './portfolio.css';
import portfolioImg from '../../assets/portfolio-img.png';
import portFolio from '../../assets/portfolio.png';
import react from '../../assets/react.svg';
import angular from '../../assets/angular.svg';
import node from '../../assets/node.svg';
import mongo from '../../assets/mongodb.svg';
import aws from '../../assets/aws.svg';
import { Link } from 'react-router-dom';
import projects from '../../utilities/projects.json';



const Portfolio = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <div className="portfolio">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6">
                            <h1 className="fw-700 mb-3">
                                Projects We’ve <br className='d-md-block d-none' />
                                Delivered
                            </h1>
                            <div className="fs-20 fw-500 text-grey">
                                Over the past 10 years, we have designed and built a wide range of high-quality products from scratch. Our team has finished various web and mobile apps related to various spheres including e-learning, healthcare, e-commerce, advertising, augmented reality, action sports, finance and sharing economy.

                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 offset-lg-3">
                            <div className="about-img position-relative">
                                <img src='./portfolio.jpg' alt="" className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>

                <section className="portFolio pb-5">
                    <div className="container">
                        {projects.map((project, i) => {
                            const isEven = i % 2 === 0;
                            const condition = isEven ? 'even' : 'odd';
                            return (
                                <React.Fragment key={i}>
                                    {condition === 'even' &&
                                        <div className="row mt-md-0 mt-md-5 pt-5">
                                            <div className="col-lg-4 px-md-0 px-4">
                                                <h1 className="count-number">{`0${i + 1}`}</h1>
                                                <Link to={`/details/${i}`}><h3 className="project-heading">{project.name}</h3></Link>
                                                <div className="project-des mb-3">
                                                    {project.desc}
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={react} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={angular} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={node} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={mongo} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={aws} alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 px-md-0 px-4 offset-lg-1 mt-md-0 mt-5">
                                                <Link to={`/details/${i}`}>
                                                    <div className="portfolio-img">
                                                        <img src={project.mainImage} alt="" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>}
                                    {condition === 'odd' &&
                                        <div className="row sm-reverse mt-5 pt-md-5">
                                            <div className="col-lg-7 px-md-0 px-4 mt-md-0 mt-5 ">
                                                <Link to={`/details/${i}`}>
                                                    <div className="portfolio-img">
                                                        <img src={project.mainImage} alt="" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-lg-4 px-md-0 px-4 offset-lg-1">
                                                <h1 className="count-number text-end">{`0${i + 1}`}</h1>
                                                <Link to={`/details/${i}`}><h3 className="project-heading text-end">{project.name}</h3></Link>
                                                <div className="project-des mb-3 text-end">
                                                    {project.desc}
                                                </div>
                                                <div className="d-flex gap-2 justify-content-end">
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={react} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={angular} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={node} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={mongo} alt="" />
                                                    </a>
                                                    <a href="javascript:void(0)" className='apps-icons'>
                                                        <img src={aws} alt="" />
                                                    </a>
                                                </div>
                                            </div>

                                        </div>}
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </section>
            </div>
        </>
    )
}

export default Portfolio
