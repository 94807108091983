import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import './style.css';
import http from '../../../../api';

const Review = () => {

    const [reviews, setReviews] = useState([]);

    const getAllReviews = () => {
        http.get('/review/get-all').then((res) => {
            console.log(res.data.data)
            setReviews(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }

    const deleteProject = (slug) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/review/${slug}`).then((res) => {
                    console.log(res.data.data)
                    getAllReviews();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    useEffect(() => {

        getAllReviews();

    }, [])


    return (
        <>
            <div className="text-end my-4">
                <Link to="create" className='btn btn-primary'>Add Review</Link>
            </div>
            {reviews?.length > 0 && <h1 className="text-center">All Reviews</h1>}
            {reviews?.length === 0 && <h1 className="text-center">Reviews Not Found!</h1>}
            <div className="row mx-0 mt-4">
                {reviews.map((item, i) => {
                    const { profileImage, fullName, feedback, company, slug } = item
                    return (
                        <div className="col-lg-6 col-md-6 mb-4" key={i}>
                            <div className="card p-3 h-100">
                                <div className="d-flex gap-3">
                                    <div className="client-image">
                                        <img src={profileImage} alt="" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-column ">
                                            <div className="company-name mb-1">{company}</div>
                                            <div className="client-name">{fullName}</div>
                                        </div>
                                        <div className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                                    <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu variant="light">
                                                    <Dropdown.Item as={NavLink} to={`update/${slug}`}>Update</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => deleteProject(slug)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-review mt-3"> {feedback} </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Review
