import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import http from '../../../../api';
import './style.css'

const Quote = () => {

    const [allQuote, setAllQuote] = useState([]);

    const getQuotes = () => {
        http.get('/quote/get-all').then((res) => {
            console.log(res)
            setAllQuote(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }

    const deleteQuote = (slug) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete('/quote/' + slug).then((res) => {
                    console.log(res);
                    getQuotes();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })

    }

    useEffect(() => {

        getQuotes();

    }, [])

    return (
        <>
            <div className="my-4 quote-container">
                {allQuote.length > 0 && <h1 className='text-center mb-4'>All Quotes</h1>}
                {allQuote.length === 0 && <h1 className='text-center mb-4'>Quotes Not Found</h1>}
                <div className="row mx-0">
                    {allQuote.map((item, i) => {
                        const { fullName, email, message, slug } = item;
                        return (
                            <div className="col-lg-12 mb-4" key={i}>
                                <div className="card p-3">
                                    <div className='text-end'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                                <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu variant="light">
                                                <Dropdown.Item onClick={() => deleteQuote(slug)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="card-title">{fullName}</div>
                                    <div className="card-title text-secondary">{email}</div>
                                    <p>{message}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Quote