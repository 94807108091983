import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import http from '../../../../api';


const Faq = () => {

    const [faqs, setFaqs] = useState([]);



    const getAllFaqs = () => {
        http.get('/faq/get-all').then((res) => {
            console.log(res.data.data)
            setFaqs(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }


    const deleteProject = (slug) => {
        console.log(slug)
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/faq/${slug}`).then((res) => {
                    console.log(res.data.data)
                    getAllFaqs();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    useEffect(() => {

        getAllFaqs();

    }, [])

    return (
        <>

            <div className="text-end my-4">
                <Link to="create" className='btn btn-primary'>Add Questions</Link>
            </div>
            {faqs?.length > 0 && <h1 className="text-center">FAQ's</h1>}
            {faqs?.length === 0 && <h1 className="text-center"> FAQ's Not Found!</h1>}
            <div className="row mx-0 mt-4">
                <div className="col-lg-12 col-md-12">
                    <Accordion defaultActiveKey="0">
                        {faqs?.map((item, i) => {
                            const { question, answer, slug } = item;
                            return (
                                <Accordion.Item eventKey={i} key={i}>
                                    <Accordion.Header >{question} </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="position-relative d-flex align-items-center justify-content-between">
                                            <p> {answer}</p>
                                            <div className="drop-icon">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                                        <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu variant="light">
                                                        <Dropdown.Item as={NavLink} to={`update/${slug}`}>Update</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => deleteProject(slug)}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            </div>

        </>
    )
}

export default Faq
