import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../api';

const Update = () => {

    const [value, setValue] = useState({ question: "", answer: "" });
    const [userError, setUserError] = useState('');
    const { slug } = useParams();

    const getFaq = () => {
        http.get(`faq/${slug}`).then((res) => {
            console.log(res);
            setValue(res.data.data);
            console.log(value)

        }).catch((e) => {
            console.log(e);
            setUserError(e.response.data.message);
        })
    }


    const isValid = () => {
        return value.question !== "" || value.answer !== ""
    }


    const handleSubmit = () => {
        // console.log(value)
        let body = {
            question: value.question,
            answer: value.answer,
        }
        http.put('/faq/edit/' + slug, body).then((res) => {
            console.log(res);
            toast.success('Faq Update successfully', { position: "top-right" });
            setValue({ question: "", answer: "" });
        }).catch((e) => {
            console.log(e)
            // console.log(e.response.data.message)
            setUserError(e.response.data.message)
        })
    };


    useEffect(() => {

        getFaq();

    }, [])



    return (
        <>
            <ToastContainer />

            <div className="create-project">
                <div className="row mx-0 justify-content-center mt-4">
                    <div className="col-lg-7">
                        <div className="card p-3">
                            <h2 className="text-center">Update Question</h2>
                            <div className="mb-3">
                                <label htmlFor="question" className="form-label">Question</label>
                                <input type="text" placeholder='Question' value={value?.question} onChange={(e) => setValue({ ...value, question: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="answer" className="form-label">Answer</label>
                                <input type="text" placeholder='Answer' value={value?.answer} onChange={(e) => setValue({ ...value, answer: e.target.value })} className="form-control" />
                            </div>
                            {userError.length > 0 && <small className="text-center d-block text-danger mb-3">{userError}</small>}
                            <button type='button' onClick={handleSubmit} disabled={!isValid()} className='btnPrimary w-100'>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Update
