import { useState } from "react";

const FormInput = (props) => {
    const [focused, setFocused] = useState(false);
    const { label, errorMessage, onChange, id, icon, type, ...inputProps } = props;

    const [passwordShow, setPasswordShow] = useState(false);

    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
        <div className="mb-3 position-relative">
            <label className="form-label">{label}</label>
            <input
                {...inputProps}
                type={!passwordShow ? type : "text"}
                onChange={onChange}
                onBlur={handleFocus}
                onFocus={() =>
                    inputProps.name === "confirmPassword" && setFocused(true)
                }
                focused={focused.toString()}
                className="form-control"
            />
            <span>{errorMessage}</span>
            {icon && <div className="show-hide small" onClick={() => setPasswordShow(!passwordShow)}>{!passwordShow ? 'Show' : 'Hide'}</div>}
        </div>
    );
};

export default FormInput;
