import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import './header.css';

const Nav = ({ show, handleShow, handleClose }) => {

    const user = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/admin/login')
        window.location.reload();
    }

    return (
        <>
            <div className="header border-bottom">
                <div className="search d-none d-lg-block">
                    <span><iconify-icon icon="ic:baseline-search"></iconify-icon></span>
                    <input type="search" placeholder='Search here' className='form-control' />
                </div>
                <div className="toggle-sm d-block d-md-none" onClick={handleShow}>
                    <div style={{ fontSize: '28px' }}>
                        <iconify-icon icon="gg:menu-left"></iconify-icon>
                    </div>
                </div>
                <ul className="user-link">
                    <li>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-example1" variant="success">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="user-img">
                                        <img src={user?.profileImage} alt="" />
                                    </div>
                                    <div className="user-name">{user?.fullName}</div>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="light">
                                {/* <Dropdown.Item>Profile</Dropdown.Item> */}
                                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Nav
