import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './style.css';
import Nav from 'react-bootstrap/Nav';
import Logo from '../../../../../assets/logo.svg';
import { Link, NavLink } from 'react-router-dom';

const Sidebar = ({ show, handleShow, handleClose }) => {

    return (
        <>
            <div className="d-none d-md-block">
                <div className="sidebar">
                    <Nav defaultActiveKey="/admin" className="flex-column">
                        <Nav.Link as={Link} to="/admin" onClick={handleShow}>
                            <div className="logo">
                                <img src={Logo} alt="" />
                            </div>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="admin" eventKey="1">Project</Nav.Link>
                        <Nav.Link as={NavLink} to="quote" eventKey="2">Quote</Nav.Link>
                        <Nav.Link as={NavLink} to="subscription" eventKey="3">Subscription</Nav.Link>
                        <Nav.Link as={NavLink} to="job" eventKey="4">Jobs</Nav.Link>
                        <Nav.Link as={NavLink} to="faq" eventKey="5">Faq</Nav.Link>
                        <Nav.Link as={NavLink} to="review" eventKey="6">Review</Nav.Link>
                    </Nav>

                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="sidebar shadow-none pt-0">
                        <Nav defaultActiveKey="/admin" className="flex-column">
                            <Nav.Link as={Link} to="/admin" onClick={handleClose}>
                                <div className="logo">
                                    <img src={Logo} alt="" />
                                </div>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="admin" onClick={handleClose} eventKey="1">Project</Nav.Link>
                            <Nav.Link as={NavLink} to="quote" onClick={handleClose} eventKey="2">Quote</Nav.Link>
                            <Nav.Link as={NavLink} to="subscription" onClick={handleClose} eventKey="3">Subscription</Nav.Link>
                            <Nav.Link as={NavLink} to="job" onClick={handleClose} eventKey="4">Jobs</Nav.Link>
                            <Nav.Link as={NavLink} to="faq" onClick={handleClose} eventKey="5">Faq</Nav.Link>
                            <Nav.Link as={NavLink} to="review" onClick={handleClose} eventKey="6">Review</Nav.Link>
                        </Nav>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Sidebar
