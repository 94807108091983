import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import defi from '../../assets/Defi.png';
import education from '../../assets/education.png';
import healthcare from '../../assets/health.png';
import Hospitality from '../../assets/hospital.png';
import ecommerce from '../../assets/e-commerce.png';
import realEstate from '../../assets/real-estate.png';
import qoute from '../../assets/qout.png';
import star from '../../assets/star.svg';
import contactUs from '../../assets/contactus-img.png';
import CountUp from 'react-countup';
import webDev from '../../assets/webDev.png';
import DappDev from '../../assets/Dapps.png';
import BusinessDev from '../../assets/businessDev.png';
import appDev from '../../assets/appDev.png';
import softDev from '../../assets/softDev.png';
import ux from '../../assets/uxDesign.png';
import user1 from '../../assets/clients/1.webp';
import user2 from '../../assets/clients/2.jpeg';
import user3 from '../../assets/clients/3.jpeg';
import user4 from '../../assets/clients/4.jpeg';
import user5 from '../../assets/clients/5.jpeg';
import react from '../../assets/react.svg';
import angular from '../../assets/angular.svg';
import node from '../../assets/node.svg';
import mongo from '../../assets/mongodb.svg';
import aws from '../../assets/aws.svg';
import team from '../../assets/team.svg';
import laptop from '../../assets/laptop.svg';
import playCircle from '../../assets/play-circle.svg';
import firstBg from '../../assets/first-bg.png';
import lineImg from '../../assets/line-img.svg';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import headingsData from '../../utilities/headings.json';
import projects from '../../utilities/projects.json';
import http from '../../api';



export const Home = ({ show, setShow, handleClose, handleShow }) => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    const slides = [
        { img: user1, para: "I'm building a large scale application with Metadots and I'm always surprised by their level of expertise. Great! Experience😎 I have always found The Metadots a pleasure to work with. They have been flexible, helpful and efficient - excellent value for money", name: "Eric", rank: "CEO EasyOZ" },
        { img: user2, para: "Metadots is a top organization.They worked hard to deliver the best possible result. It is fun working with them, knowing that everything is working when testing the results. I am excited to do the next project with them.", name: "Martin", rank: "CEO Smartup" },
        { img: user3, para: "Grant completely re built my website for me during the covid-19 lockdown. They took my brief and injected their own creative ideas and showed an excellent understanding of the style I was trying to create. They worked quickly and was there when needed. Excellent!", name: "Mohd Abdullah", rank: "CEO InstaJobsPh" },
        { img: user4, para: "Extremely helpful, user-friendly, innovative and reliable. Very satisfactory outcome, knowing that I can go back to them at any time for further help.", name: "Mihai", rank: "CEO CryptoCrade" },
        { img: user5, para: "I connected with Metadots last year and I been with them ever since. I have submitted all of my tech requirements and they have not only helped me along the way but also guided my through the entire technical process.Thank you for all the exceptional work that you do. Looking forward to continuing a strong partnership for lifetime.", name: "James A.", rank: "CEO  YOGF Studios" },
    ]

    const experienceCard = [
        {
            img: webDev, heading: 'Web App Development', para: 'Emphasis on developing fast, secure and scalable web applications for client’s business needs.'
        },
        {
            img: DappDev, heading: 'DApp Development', para: 'Using the power of web 3.0 to develop decentralized applications (DApps).'
        },
        {
            img: BusinessDev, heading: 'Blockchain', para: 'Unlocking the true potential of distributed ledger to establish and maintain trust amongst stakeholders.'
        },
        {
            img: appDev, heading: 'Mobile App Development', para: 'Optimizing user experience by developing robust mobile applications to scale your business and reach more audience.'
        },
        {
            img: softDev, heading: 'DevOps', para: 'Automating end-to-end delivery cycle to ship new features on daily basis.'
        },
        {
            img: ux, heading: 'UI & UX Design', para: 'Designing delightful prototypes and mock-ups before starting the product development.'
        },
    ]


    const [contactInfo, setContactInfo] = useState({ fullName: "", email: "", phoneNumber: "", message: "" });
    const [messageShow, setMessageShow] = useState(false);
    const [newsLetterShow, setNewsLetterShow] = useState(false);
    const [emailBox, setEmailBox] = useState('');
    const [emailValid, setEmailValid] = useState(false);


    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        return (false)
    }

    const sendEmail = () => {
        if (ValidateEmail(contactInfo.email)) {
            http.post('/quote/new', contactInfo).then((res) => {
                console.log(res);
                if (res) {
                    setMessageShow(true);
                }
                setContactInfo({ fullName: "", email: "", phoneNumber: "", message: "" })
            }).catch((e) => {
                console.log(e);
            })
        } else {
            setEmailValid(true)

        }
    };

    const isValid = () => {
        return contactInfo.fullName !== "" && contactInfo.email !== "" && contactInfo.phoneNumber !== "" && contactInfo.message !== "" && contactInfo.email.includes('@')
    }

    const [headerContent, setHeaderContent] = useState(headingsData.headings);
    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const previousSlide = () => {
        const shouldResetIndex = currentContentIndex === 0;
        const index = shouldResetIndex ? headerContent.length - 1 : currentContentIndex - 1;
        setCurrentContentIndex(index);

    }

    const nextSlide = () => {
        const shouldResetIndex = currentContentIndex === headerContent.length - 1;
        const index = shouldResetIndex ? 0 : currentContentIndex + 1;
        setCurrentContentIndex(index);
    }


    const subscribeNewsLetter = () => {
        if (emailBox.includes('@') && ValidateEmail(emailBox)) {
            http.post('/subscription/subscribe', { email: emailBox }).then((res) => {
                console.log(res);
                if (res) {
                    setNewsLetterShow(true);
                }
                setEmailBox('');
            }).catch((e) => console.log(e))
        } else {
            setEmailValid(true)

        }
    }

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });




    }, [])

    useEffect(() => {

        setTimeout(() => {

            setMessageShow(false);

            setNewsLetterShow(false);

        }, 5000);


    }, [messageShow, newsLetterShow])




    return (
        <>

            <header>
                <div className="container">
                    <div className="row mx-0 justify-content-between">
                        <div className="col-md-4 mb-md-0 mb-5">
                            <h1 className="fw-700">
                                Where Ideas meet
                                Reality
                            </h1>
                            <div className="line-img position-relative">
                                <img src={lineImg} className="w-100" alt="" />
                                <div className="whiteBox"></div>
                            </div>
                            <p className='ff-rubik text-grey fw-400 fs-20 mb-lg-5 mb-3'>
                                Re-Imagining our strategy solutions & platforms
                                according to your business needs, our qualified team of engineers and business analysts is here to shape the future of your business.
                            </p>
                            {/* <div className="d-flex align-items-center gap-3 mb-3">
                                <a href="javascript:void(0)" >
                                    <img src={playCircle} alt="" />
                                </a>
                                <a href="javascript:void(0)" className='ff-rubik fs-20 textPrimary fw-600 '>Watch Video</a>
                            </div>
                            <div className="fs-12 fw-400 text-grey mb-lg-5 pb-lg-5 pb-3">
                                Watch how Metadots is creating value for businesses in various industries.
                            </div> */}
                            {newsLetterShow && <Alert variant={'info'} className="my-4">
                                <span className="d-inline-flex gap-1 fs-20 align-items-center"><iconify-icon icon="mdi:tick-circle-outline" ></iconify-icon>
                                    <small className='fs-12'>Thank you for subscribing to our newsletter.</small> </span>
                            </Alert>}
                            <div className="searchWithBtn">
                                <span className="iconify" data-icon="ant-design:mail-filled"></span>
                                <input type="text" value={emailBox} className={emailValid && emailBox.length > 4 && 'border-danger text-danger'} onChange={(e) => setEmailBox(e.target.value)} placeholder="Email Address" />
                                <button id="cubic" type='button' onClick={subscribeNewsLetter} disabled={emailBox.length > 4 && emailBox.includes('@') ? false : true}>
                                    <span title='Subscribe'>Subscribe</span>
                                </button>
                            </div>

                        </div>
                        <div className="col-md-7 px-0 mb-5 mb-md-0 header-slide">
                            {headerContent.map((heading, i) => {
                                return (
                                    currentContentIndex === i &&
                                    <div className="position-relative" key={i}>
                                        <div className="first-img position-relative" >
                                            <img src={heading.img} className={currentContentIndex === i ? "w-100 slide-head active-show" : "w-100 slide-head"} alt="" />
                                            <div className={currentContentIndex === i ? "overlay slide-head active-show" : "overlay slide-head"}></div>
                                            <div className={currentContentIndex === i ? "first-content slide-head active-show" : "first-content slide-head"}>
                                                <div className="fs-24 fw-600 text-white mb-2">{heading.mainHeading}</div>
                                                <div className="fs-14 fw-500 text-white mb-4">
                                                    {heading.mainDesc}
                                                </div>
                                                <div>
                                                    <button type='button' onClick={handleShow} className="btnPrimaryOutline"><span>Start Building</span></button>
                                                </div>
                                            </div>

                                            <div className="tagline-content ">
                                                <div className='d-flex align-items-end justify-content-between index-1'>
                                                    <div className={currentContentIndex === i ? "slide-head active-show" : "slide-head"}>
                                                        <div className="fs-16 fw-600 text-white">
                                                            {heading.smHeading}
                                                        </div>
                                                        <div className="fs-12 fw-500 text-white w-lg-75">
                                                            {heading.smDesc}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2 ">
                                                        <button type='button' onClick={previousSlide} className='tag-arrow active bg-transparent border-0 '>
                                                            <span class="iconify" data-icon="ic:round-navigate-before"></span>
                                                        </button>
                                                        <button type='button' onClick={nextSlide} className='tag-arrow active bg-transparent border-0 '>
                                                            <span class="iconify" data-icon="ic:round-navigate-next"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="count-number">
                                            {heading.count}
                                        </div>
                                    </div>

                                )
                            })}
                            {/* <div className="position-relative">
                                <div className="first-img position-relative">
                                    <img src={headerContent[currentContentIndex].img} className="w-100" alt="" />
                                    <div className="first-content">
                                        <div className="fs-24 fw-600 text-white mb-2">{headerContent[currentContentIndex].mainHeading}</div>
                                        <div className="fs-14 fw-500 text-white mb-4">
                                            {headerContent[currentContentIndex].mainDesc}
                                        </div>
                                        <div>
                                            <button type='button' className="btnPrimaryOutline"><span>Start Building</span></button>
                                        </div>
                                    </div>

                                    <div className="tagline-content ">
                                        <div className="d-flex align-items-end justify-content-between index-1">
                                            <div>
                                                <div className="fs-16 fw-600 text-white">
                                                    {headerContent[currentContentIndex].smHeading}
                                                </div>
                                                <div className="fs-12 fw-500 text-white w-lg-75">
                                                    {headerContent[currentContentIndex].smDesc}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center gap-2 ">
                                                <button type='button' onClick={previousSlide} className='tag-arrow active bg-transparent border-0 '>
                                                    <span class="iconify" data-icon="ic:round-navigate-before"></span>
                                                </button>
                                                <button type='button' onClick={nextSlide} className='tag-arrow active bg-transparent border-0 '>
                                                    <span class="iconify" data-icon="ic:round-navigate-next"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="count-number">
                                    {headerContent[currentContentIndex].count}
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </header>


            <section className="techExperience mt-5 pt-5">
                <div className="container">
                    <div className="px-3 px-md-0 ff-rubik">
                        <div className="fs-60 fw-700 textSecondary">We create impact!</div>
                        <p className="fs-24 fw-500 textSecondary">View all the services we offer <span className="horizontalLine"></span>
                        </p>
                    </div>


                    <div className="row mx-0 justify-content-between align-items-center">
                        <div className="col-lg-3 ps-lg-5">
                            <div className="fs-100 fw-700 textPrimary ff-rubik">
                                <CountUp delay={3} duration={2} end={6} />
                                +</div>
                            <div className="fs-24 fw-600 textSecondary">Years <br className="d-lg-block d-none" />
                                Experience <br className="d-lg-block d-none" />
                                Working</div>
                        </div>
                        <div className="col-lg-9 px-0">
                            <div className="row mx-0">
                                {experienceCard.map((card, index) => {
                                    const { img, heading, para } = card
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="col-md-4 col-6 mb-4">
                                                <div className="experienceCard">
                                                    <div className="viewcontent mb-md-4 mb-3">
                                                        <img src={img} height={64} width={64} alt="" />
                                                        <p className="fs-14 fw-500 text-white">{para}</p>
                                                    </div>
                                                    <div className="fs-20 fw-600 textSecondary">{heading} </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                            <div className="row mx-0 d-none">
                                <div className="col-md-4 col-6 mb-4">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="el:screen"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">Web <br className='d-lg-block d-none' /> Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mb-4">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="bx:network-chart"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">DApps <br />
                                            Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mb-4">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="fa6-solid:mobile-screen"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">App <br className="d-lg-block d-none" />
                                            Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="bx:network-chart"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">DApps <br />
                                            Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 my-4 my-md-0">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="bx:network-chart"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">DApps <br />
                                            Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="experienceCard">
                                        <div className="mb-4">
                                            <span className="iconify textSecondary" data-icon="fa6-solid:mobile-screen"></span>
                                        </div>
                                        <div className="fs-20 fw-600 textSecondary">Apps <br />
                                            Development</div>
                                        <div className="viewMore">
                                            <a href="javascript:void(0)" className='fs-12 fw-400 text-white'>View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="portFolio ">
                <div className="container">
                    <div className="row mx-0 justify-content-lg-end ">
                        <div className="col-lg-6 ff-rubik">
                            <div className="fs-60 fw-700 textSecondary">Successful Projects </div>
                            <p className="fs-24 fw-500 textSecondary text-start">Delivering value for our clients
                                <span className="horizontalLine"></span>
                            </p>
                        </div>
                    </div>

                    {projects.slice(0, 4).map((project, i) => {
                        const isEven = i % 2 === 0;
                        const condition = isEven ? 'even' : 'odd';
                        return (
                            <React.Fragment key={i}>
                                {condition === 'even' &&
                                    <div className="row mt-md-0 mt-md-5 pt-5">
                                        <div className="col-lg-4 px-md-0 px-4">
                                            <h1 className="count-number">{`0${i + 1}`}</h1>
                                            <Link to={`/details/${i}`}><h3 className="project-heading">{project.name}</h3></Link>
                                            <div className="project-des mb-3">
                                                {project.desc}
                                            </div>
                                            <div className="d-flex gap-2">
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={react} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={angular} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={node} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={mongo} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={aws} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 px-md-0 px-4 offset-lg-1 mt-md-0 mt-5">
                                            <Link to={`/details/${i}`}>
                                                <div className="portfolio-img">
                                                    <img src={project.mainImage} alt="" />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>}
                                {condition === 'odd' &&
                                    <div className="row sm-reverse mt-5 pt-md-5">
                                        <div className="col-lg-7 px-md-0 px-4 mt-md-0 mt-5 ">
                                            <Link to={`/details/${i}`}>
                                                <div className="portfolio-img">
                                                    <img src={project.mainImage} alt="" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-4 px-md-0 px-4 offset-lg-1">
                                            <h1 className="count-number text-end">{`0${i + 1}`}</h1>
                                            <Link to={`/details/${i}`}><h3 className="project-heading text-end">{project.name}</h3></Link>
                                            <div className="project-des mb-3 text-end">
                                                {project.desc}
                                            </div>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={react} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={angular} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={node} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={mongo} alt="" />
                                                </a>
                                                <a href="javascript:void(0)" className='apps-icons'>
                                                    <img src={aws} alt="" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>}
                            </React.Fragment>
                        )
                    })
                    }


                    <div className="d-flex justify-content-center mt-lg-5 pt-5">
                        <Link id="cubic" to="portfolio" className="" >
                            <span title='View Portfolio'>View Portfolio</span>
                        </Link>
                    </div>
                </div>

            </section>

            {/* engagements model */}

            <section className="engagements-model mt-md-5 pt-md-5">
                <div className="container mt-lg-5 pt-lg-5">
                    <div class="fs-60 fw-700 textSecondary position-relative">ENGAGEMENT MODELS
                        <span className="horizontalLine"></span>
                    </div>
                    <div className="row mt-md-5 pt-5">
                        <div className="col-lg-6">
                            <div className="engagement-padding">
                                <div className="team-icon mb-5">
                                    <img src={team} alt="" />
                                </div>
                                <div className="fs-45 fw-600 textSecondary mb-md-5 mb-3 text-md-start text-center">
                                    Build Your <br className='d-md-block d-none' />
                                    Dedicated Teams
                                </div>
                                <div className="fs-30 fw-700 engage-heading textSecondary mb-2 text-md-start text-center">Our teams at your service</div>
                                <div className="fs-20 fw-400 text-grey engage-para text-md-start text-center">
                                    Get your projects fast-tracked with best technical and management talent. Increase quality and efficiency, and retain complete control of teams.
                                </div>

                                <div className="mt-5">
                                    {/* <button type='button' className='btnPrimary'>Build your team</button> */}
                                    <button id="cubic" type='button' onClick={handleShow} className="px-0" >
                                        <span title='Build your team'>Build your team</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-5">
                            <div className="engagement-bg">
                                <div className="laptop-img mb-5">
                                    <img src={laptop} alt="" />
                                </div>
                                <div className="fs-45 fw-600 text-white mb-5">
                                    Fixes Price <br />
                                    Projects
                                </div>
                                <div className="fs-30 fw-700 text-white engage-heading mb-2">
                                    We design and develop your vision
                                </div>
                                <div className="fs-20 fw-400 text-lightgrey engage-para">
                                    Get a comprehensive product development experience from design to delivery with reduced development costs.
                                </div>

                                <div className="mt-5">
                                    <button type='button' onClick={handleShow} className='btnWhite fs-16 fw-500'><span>Start Building</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="workWith pt-md-5 mt-5">
                <div className="container mt-md-5 pt-lg-5">
                    <div className="row mx-0 justify-content-lg-end pb-lg-5 pb-3 mt-md-5">
                        <div className="col-lg-7 ff-rubik">
                            <div className="fs-60 fw-700 textSecondary nowrap">Industries we work with </div>
                            <p className="fs-24 fw-500 textSecondary text-start">Our experties in various industries
                                <span className="horizontalLine"></span>
                            </p>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-4 col-6 mb-5 text-center">
                            <div className="icon-img">
                                <img src={defi} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">DeFi</div>
                        </div>
                        <div className="col-md-4 col-6 mb-5 text-center">
                            <div className="icon-img">
                                <img src={education} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">Education</div>
                        </div>
                        <div className="col-md-4 col-6 mb-5 text-center ">
                            <div className="icon-img">
                                <img src={healthcare} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">Healthcare</div>
                        </div>
                        <div className="col-md-4 col-6 text-center ">
                            <div className="icon-img">
                                <img src={Hospitality} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">Hospitality</div>
                        </div>
                        <div className="col-md-4 col-6 text-center ">
                            <div className="icon-img">
                                <img src={ecommerce} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">E-Commerce</div>
                        </div>
                        <div className="col-md-4 col-6 text-center">
                            <div className="icon-img">
                                <img src={realEstate} alt="" />
                            </div>
                            <div className="fs-20 fw-500 textSecondary">Real Estate</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="customerSays mt-md-5 pt-md-5">
                <div className="container">
                    <div className="fs-60 fw-700 textSecondary nowrap ff-rubik">What Our Customer Says? </div>
                    <span className="horizontalLine"></span>
                    <div className="mt-md-5 pt-md-5">
                        <Slider {...settings}>
                            {slides.map((item, index) => {
                                const { img, name, rank, para } = item
                                return (
                                    <React.Fragment key={index}>
                                        <div className="testimonialCard">
                                            <div className="qoute"><img src={qoute} alt="" /></div>
                                            <div className="testiMain">
                                                <div className="d-flex mb-3">
                                                    <div className="star me-2">
                                                        <img src={star} width="20" height="20" alt="" />
                                                    </div>
                                                    <div className="star me-2">
                                                        <img src={star} width="20" height="20" alt="" />
                                                    </div>
                                                    <div className="star me-2">
                                                        <img src={star} width="20" height="20" alt="" />
                                                    </div>
                                                    <div className="star me-2">
                                                        <img src={star} width="20" height="20" alt="" />
                                                    </div>
                                                    <div className="star">
                                                        <img src={star} width="20" height="20" alt="" />
                                                    </div>
                                                </div>
                                                <p className="fs-18 fw-400 line-clamp">
                                                    {para}
                                                </p>
                                            </div>
                                            <div className="testFooter">
                                                <div className="d-flex">
                                                    <div className="user me-3">
                                                        <img src={img} alt="" />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="fs-16 fw-800">{name}</div>
                                                        <div className="fs-14 fw-600">{rank}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </Slider>
                    </div>


                </div>
            </section>

            <section className="yearsExperince ">
                <div className="container mt-lg-5 pt-lg-5">
                    <div className="row mx-0 align-items-center ff-rubik">
                        <div className="col-md-4 text-center">
                            <div className="fs-100 fw-700 mb-2">
                                <CountUp duration={3} end={6} />
                                +</div>
                            <div className="fs-30 fw-700 textSecondary">Years of experience</div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fs-100 fw-700 mb-2">
                                <CountUp end={50} />
                                +</div>
                            <div className="fs-30 fw-700 textSecondary">Happy Clients</div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fs-100 fw-700 mb-2">
                                <CountUp end={200} />
                                +</div>
                            <div className="fs-30 fw-700 textSecondary">Successful Projects</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contactUs mb-md-5 pb-lg-5">
                <div className="container mt-md-5 pt-md-5 mb-lg-5 pb-lg-5">
                    <div className="row mx-0">
                        <div className="col-lg-4 ps-lg-0 text-center d-none d-lg-block">
                            <img src={contactUs} className="w-100" alt="" />
                            <div className="fs-40 fw-500 textSecondary ff-rubik">You have an idea? <br className="d-lg-block d-none" />
                                We handle all the rest.

                            </div>
                        </div>
                        <div className="col-lg-7 offset-lg-1 pe-lg-0">
                            <div className="fs-60 fw-700 textSecondary nowrap ff-rubik">Contact Us </div>
                            <span className="horizontalLine"></span>
                            {messageShow && <Alert variant={'info'} className="my-4">
                                Your message has been sent successfully.
                            </Alert>}
                            <div className="row mx-0 mt-5 mb-38">
                                <div className="col-md-12 px-0 mb-3 mb-md-0">
                                    <div className="inputWithIcon">
                                        <span className="iconify" data-icon="ant-design:user-outlined"></span>
                                        <input type="text" value={contactInfo.fullName} onChange={(e) => setContactInfo({ ...contactInfo, fullName: e.target.value })} placeholder="Full Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 mb-38">
                                <div className="col-md-6 ps-lg-0 px-0 mb-3 mb-md-0">
                                    <div className="inputWithIcon">
                                        <span className="iconify" data-icon="ant-design:mail-outlined"></span>
                                        <input type="email" value={contactInfo.email} className={emailValid && contactInfo.email.length > 4 && 'border-danger text-danger'} onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })} placeholder="Email Address" />
                                    </div>
                                </div>
                                <div className="col-md-6 ps-md-3 px-0">
                                    <div className="inputWithIcon">

                                        <span className="iconify" data-icon="bx:phone"></span>
                                        <input type="number" value={contactInfo.phoneNumber} onChange={(e) => setContactInfo({ ...contactInfo, phoneNumber: e.target.value })} placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 mb-38">
                                <div className="col-12 ps-lg-0 px-0">
                                    <textarea rows="10" value={contactInfo.message} onChange={(e) => setContactInfo({ ...contactInfo, message: e.target.value })} placeholder="Please tell us how can we help you?"></textarea>
                                </div>
                            </div>
                            <div className="text-center text-md-start">
                                <button id="cubic" type='button' disabled={!isValid() ? true : false} onClick={sendEmail} className="" >
                                    <span title='Send Message'>Send Message</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
