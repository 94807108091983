import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import http from '../../../../api';


const Job = () => {

    const [allJob, setAllJob] = useState([]);

    const getAllJob = () => {
        http.get('/job/get-all').then((res) => {
            console.log(res)
            setAllJob(res.data.data);
        }).catch((e) => {
            console.log(e)
        })
    }

    const deleteJob = (slug) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                http.delete(`/job/${slug}`).then((res) => {
                    console.log(res.data.data)
                    getAllJob();
                }).catch((e) => {
                    console.log(e)
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }

    useEffect(() => {

        getAllJob();

    }, [])


    return (
        <>
            <div className="text-end my-4">
                <Link to="create" className='btn btn-primary'>Add Job</Link>
            </div>

            {allJob?.length > 0 && <h1 className="text-center">Jobs</h1>}
            {allJob?.length === 0 && <h1 className="text-center">Jobs Not Found!</h1>}
            <div className="row mx-0 my-4">
                {allJob.map((job, i) => {
                    const { title, description, location, experience, slug } = job
                    return (
                        <div className="col-lg-4 mb-4" key={i}>
                            <div className="card p-3 h-100">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <h5 class="card-title mb-0">{title}</h5>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-button-example1" variant="success" className='bg-transparent text-secondary border-0'>
                                            <iconify-icon icon="mdi:ellipsis-vertical"></iconify-icon>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu variant="light">
                                            <Dropdown.Item as={NavLink} to={`update/${slug}`}>Update</Dropdown.Item>
                                            <Dropdown.Item onClick={() => deleteJob(slug)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <h6 class="card-subtitle mb-2 text-muted">{location}</h6>
                                <p class="card-text">{description}</p>
                                <h6 class="card-subtitle mb-2 text-muted">{experience}, Experience</h6>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Job
