import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.svg';
import './Style/footer.css';
import Social from './socialIcons';


export const Footer = () => {

    const location = useLocation();

    return (
        <>

            <footer>
                <div class="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="foot-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="my-3">
                                <div className="fs-16 fw-500 text-lightgrey text-capitalize d-flex align-items-center gap-2 mb-2">
                                    <iconify-icon icon="material-symbols:share-location"></iconify-icon>
                                    81-G3 Johar Town, Lahore Pakistan
                                </div>
                                <div className="fs-16 fw-500 text-lightgrey d-flex align-items-center gap-2">
                                    <iconify-icon icon="ic:round-call"></iconify-icon>  +92 301 6475925
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 mt-md-0 mt-4">
                            <div className="fs-22 fw-500 text-white">
                                Page  Links
                            </div>
                            <ul className='mt-3'>
                                <li className='mb-3'>
                                    {location.pathname !== '/' && <Link to="/" className='fs-16 fw-600 text-lightgrey'>Home</Link>}
                                </li>
                                <li className='mb-3'>
                                    {location.pathname !== '/portfolio' && <Link to="/portfolio" className='fs-16 fw-600 text-lightgrey'>Portfolio</Link>}
                                </li>
                                <li className='mb-3'>
                                    {location.pathname !== '/about' && <Link to="/about" className='fs-16 fw-600 text-lightgrey'>About Us</Link>}
                                </li>
                                <li className='mb-3'>
                                    {location.pathname !== '/career' && <Link to="/career" className='fs-16 fw-600 text-lightgrey'>Career</Link>}
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                            <div className="fs-22 fw-500 text-white mb-3">
                                Open Hours
                            </div>

                            <div className="d-flex justify-content-between align-items-center fs-14 fw-500 text-lightgrey mb-3">
                                <div className='d-flex align-items-center'>
                                    <div className="fs-16"><iconify-icon icon="mdi:clock-time-four-outline"></iconify-icon></div>
                                    <span className="d-inline-block ms-2">Monday to Friday</span>
                                </div>
                                <div>
                                    24 hours
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center fs-14 fw-500 text-lightgrey mb-3">
                                <div className='d-flex align-items-center'>
                                    <div className="fs-16"><iconify-icon icon="mdi:clock-time-four-outline"></iconify-icon></div>
                                    <span className="d-inline-block ms-2">Saturday</span>
                                </div>
                                <div>
                                    11:00 AM - 08:00 PM
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center fs-14 fw-500 text-lightgrey mb-3">
                                <div className='d-flex align-items-center'>
                                    <div className="fs-16"><iconify-icon icon="mdi:clock-time-four-outline"></iconify-icon></div>
                                    <span className="d-inline-block ms-2">Sunday</span>
                                </div>
                                <div>
                                    11:00 AM - 08:00 PM
                                </div>
                            </div>

                        </div>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
                        <p class="fs-14 fw-500 text-white text-center ff-rubik mb-0">Copyright © 2023 Metadots. All rights reserved </p>
                        <Social />
                    </div>
                </div>
            </footer>

        </>
    )
}
