import React, { useEffect } from "react";
import "./career.css";
import portfolioImg from "../../assets/portfolio-img.png";
import jobs from "../../utilities/career.json";

const Career = () => {
	const sendEmail = () => {
		window.open("mailto:hr@metadots.co");
	};

	console.log(jobs, "Job here");

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<div className="career-page">
				<div className="container">
					<div className="row mt-5 mb-5 mb-md-0">
						<div className="col-lg-4 col-md-6">
							<h1 className="fw-700 mb-3">Where talent meets excellence</h1>
							<div className="fs-20 fw-500 text-grey">
								At Metadots we expect to work alongside smart and passionate individuals who want to build a better tomorrow. We go over and beyond for our clients and deliver projects using the
								latest technologies, innovations and practices. We invest heavily in Research & Development and consistently push each other to learn and grow together.
							</div>
						</div>
						<div className="col-lg-5 col-md-6 offset-lg-3">
							<div className="about-img position-relative">
								<img src={portfolioImg} alt="" className="w-100" />
							</div>
						</div>
					</div>

					<div className="career-faqs">
						<div class="accordion" id="accordionExample">
							{jobs.map((job, i) => {
								return (
									<div class="accordion-item" key={i}>
										<h2 class="accordion-header" id={"headingOne" + i}>
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + i} aria-expanded="true" aria-controls={"collapseOne" + i}>
												<div className="fs-34 fw-600 mb-2">{job?.jobTitle}</div>
												<div className="d-flex align-items-center custom-margin gap-4">
													<div className="location d-flex align-items-center gap-2">
														<span className="icon">
															<span class="iconify" data-icon="ic:outline-share-location"></span>
														</span>
														<div className="fs-24 fw-500">{job?.location}</div>
													</div>
													<div className="location d-flex align-items-center gap-2">
														<span className="icon">
															<span class="iconify" data-icon="mdi:like-outline"></span>
														</span>
														<div className="fs-24 fw-500">{job?.jobExperience}</div>
													</div>
												</div>
												<div className="text-center">
													<button type="button" className="btnWhite px-5 py-3" onClick={sendEmail}>
														Apply Now
													</button>
												</div>
											</button>
										</h2>
										<div id={"collapseOne" + i} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div className="fs-30 fw-700 textSecondary mb-md-4 mb-2">Job Description</div>
												<p className="mb-0 fs-24 fw-500 text-grey mb-md-5 mb-3">{job?.jobDescription}</p>

												<div className="fs-30 fw-700 textSecondary mb-md-4 mb-2">Requirements</div>
												<ul className="mb-md-5 mb-3 list-group list-group-numbered border-0">
													{job?.requirements?.map((req, i) => {
														return (
															<li className="fs-18 fw-500 text-grey" key={i}>
																{req?.text}
															</li>
														);
													})}
												</ul>

												<div className="fs-30 fw-700 textSecondary mb-md-4 mb-2">Perks and Benefits </div>
												<ul className="mb-md-5 mb-3 list-group list-group-numbered border-0">
													{job?.perksAndBenefits?.map((perk, i) => {
														return (
															<li className="fs-18 fw-500 text-grey" key={i}>
																{perk?.text}
															</li>
														);
													})}
												</ul>

												<div className="fs-30 fw-700 textSecondary mb-md-4 mb-2">Experience </div>
												<ul className="mb-md-5 mb-3 list-group list-group-numbered border-0">
													{job?.experience?.map((exp, i) => {
														return (
															<li className="fs-18 fw-500 text-grey" key={i}>
																{exp?.text}
															</li>
														);
													})}
												</ul>
												<div className="text-center">
													{/* <button type='button' className='btnPrimary'>I’m Interested</button> */}
													<button id="cubic" type="button" className="" onClick={sendEmail}>
														<span title="I’m Interested">I’m Interested</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Career;
